import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { tokens } from '@carta/ink';
import { formatNumber } from '@carta/pen';
import * as React from 'react';
export default {
  Highcharts,
  HighchartsReact,
  tokens,
  formatNumber,
  React
};