import * as React from 'react';
import { NewTable, useTableVirtualizer, useSelectable, useSort, NewCheckbox } from '@carta/ink';
import * as falso from '@ngneat/falso';

const securityTypes = {
  'equity-common': 'Common shares',
  'equity-preferred': 'Preferred shares',
  'equity-convertible': 'Convertible notes',
  'equity-warrant': 'Warrants',
  'equity-option': 'Option grants',
  'equity-pool': 'Option pool',
} as const;

falso.seed('virtual-table');
const numberFactory = falso.incrementalNumber();
const rowCount = 10000;
const data = falso.toCollection(
  () => ({
    id: numberFactory(),
    securityType: falso.rand(Object.keys(securityTypes)),
    name: falso.randFullName(),
    security: `${falso.randAbbreviation()}-${falso.randNumber({
      min: 10,
      max: 100,
    })}`,
  }),
  { length: rowCount },
) as Array<{
  id: number;
  securityType: keyof typeof securityTypes;
  name: string;
  security: string;
}>;

export const VirtualTable = () => {
  const [selected, selectActions] = useSelectable(data);
  const [sorted, sortActions] = useSort(data, {
    initialState: { key: 'id', direction: 'ascending' },
  });

  const virtualizer = useTableVirtualizer(sorted.items as typeof data);

  return (
    <NewTable.VirtualWrapper height="400px" {...virtualizer.getWrapperProps()}>
      <NewTable>
        <NewTable.Head>
          <NewTable.Row>
            <NewTable.HeadCell width="8%">
              <NewCheckbox id="select-all" checked={selected.allRows} onChange={selectActions.toggleAllRows} />
            </NewTable.HeadCell>
            <NewTable.HeadCell width="8%">
              <NewTable.Pin {...sortActions.getPinProps('id')}>ID</NewTable.Pin>
            </NewTable.HeadCell>
            <NewTable.HeadCell width="28%">
              <NewTable.Pin {...sortActions.getPinProps('securityType')}>Type</NewTable.Pin>
            </NewTable.HeadCell>
            <NewTable.HeadCell width="28%">
              <NewTable.Pin {...sortActions.getPinProps('name')}>Stakeholder</NewTable.Pin>
            </NewTable.HeadCell>
            <NewTable.HeadCell width="28%">
              <NewTable.Pin {...sortActions.getPinProps('security')}>Security</NewTable.Pin>
            </NewTable.HeadCell>
          </NewTable.Row>
        </NewTable.Head>
        <NewTable.Body {...virtualizer.getBodyProps()}>
          {virtualizer.getVirtualItems().map(virtualRow => {
            const stakeholder = virtualizer.getRowItem(virtualRow);
            return (
              <NewTable.Row {...virtualizer.getRowProps(virtualRow)} selected={selected.rows[stakeholder.id]}>
                <NewTable.Cell width="8%">
                  <NewTable.Stripe variant={stakeholder.securityType} />
                  <NewCheckbox
                    id={stakeholder.id.toString()}
                    onChange={selectActions.toggleRow}
                    checked={selected.rows[stakeholder.id]}
                  />
                </NewTable.Cell>
                <NewTable.Cell width="8%">{stakeholder.id}</NewTable.Cell>
                <NewTable.Cell width="28%">{securityTypes[stakeholder.securityType]}</NewTable.Cell>
                <NewTable.Cell width="28%">{stakeholder.name}</NewTable.Cell>
                <NewTable.Cell width="28%">{stakeholder.security}</NewTable.Cell>
              </NewTable.Row>
            );
          })}
        </NewTable.Body>
      </NewTable>
    </NewTable.VirtualWrapper>
  );
};
