export type IconTypes =
  | '2fa-n'
  | '2fa'
  | 'account-search'
  | 'account'
  | 'add'
  | 'backward-bold'
  | 'backward'
  | 'bank'
  | 'box'
  | 'calculator'
  | 'calendar'
  | 'capitalization'
  | 'chat'
  | 'checkmark'
  | 'close'
  | 'collapse-all'
  | 'company'
  | 'compliance'
  | 'corporation'
  | 'credit-card'
  | 'delete'
  | 'document'
  | 'dollar'
  | 'down-arrow'
  | 'down-bold'
  | 'down-carat'
  | 'down'
  | 'download'
  | 'drag-n-drop'
  | 'edit'
  | 'error'
  | 'exchange'
  | 'exit'
  | 'expand-all'
  | 'file-inverted'
  | 'file'
  | 'filter-1'
  | 'filter-2'
  | 'filter'
  | 'forward-bold'
  | 'forward'
  | 'gear'
  | 'globe'
  | 'group'
  | 'hamburger'
  | 'hamburger-collapse'
  | 'help'
  | 'hide'
  | 'hourglass'
  | 'information'
  | 'instructions'
  | 'invest'
  | 'law'
  | 'left-arrow'
  | 'loading'
  | 'lock'
  | 'mail'
  | 'minus'
  | 'more-horizontal'
  | 'more-vertical'
  | 'note'
  | 'open-in-new'
  | 'paperclip'
  | 'pencil'
  | 'people'
  | 'permissions'
  | 'phone2'
  | 'pin'
  | 'plant'
  | 'play'
  | 'portfolio'
  | 'preview'
  | 'print'
  | 'questionmark'
  | 'quickaction'
  | 'right-arrow'
  | 'search'
  | 'securities'
  | 'send'
  | 'setup'
  | 'sort-2'
  | 'sort-carat'
  | 'sort'
  | 'spaceship'
  | 'star'
  | 'starfill'
  | 'table'
  | 'tags'
  | 'tasklist'
  | 'transactions'
  | 'trophy'
  | 'universal-download'
  | 'universal-search'
  | 'unlock'
  | 'up-bold'
  | 'up-carat'
  | 'up'
  | 'upload'
  | 'user'
  | 'users'
  | 'warning'
  | 'zoom'
  | '';

export const STATIC_ICONS: IconTypes[] = [
  '2fa-n',
  '2fa',
  'account-search',
  'account',
  'add',
  'backward-bold',
  'backward',
  'bank',
  'box',
  'calculator',
  'calendar',
  'capitalization',
  'chat',
  'checkmark',
  'close',
  'collapse-all',
  'company',
  'compliance',
  'corporation',
  'credit-card',
  'delete',
  'document',
  'dollar',
  'down-bold',
  'down-carat',
  'down',
  'download',
  'drag-n-drop',
  'edit',
  'error',
  'exchange',
  'exit',
  'expand-all',
  'file-inverted',
  'file',
  'filter-2',
  'filter-1',
  'filter',
  'forward-bold',
  'forward',
  'gear',
  'globe',
  'group',
  'hamburger',
  'hamburger-collapse',
  'help',
  'hide',
  'hourglass',
  'information',
  'instructions',
  'invest',
  'law',
  'left-arrow',
  'loading',
  'lock',
  'mail',
  'minus',
  'more-horizontal',
  'more-vertical',
  'note',
  'open-in-new',
  'paperclip',
  'pencil',
  'permissions',
  'phone2',
  'pin',
  'plant',
  'play',
  'portfolio',
  'preview',
  'print',
  'questionmark',
  'quickaction',
  'right-arrow',
  'search',
  'securities',
  'send',
  'setup',
  'sort-carat',
  'sort',
  'sort-2',
  'spaceship',
  'star',
  'starfill',
  'table',
  'tags',
  'tasklist',
  'transactions',
  'trophy',
  'universal-download',
  'universal-search',
  'unlock',
  'up-bold',
  'up-carat',
  'up',
  'upload',
  'user',
  'users',
  'warning',
  'zoom',
];

export const AVAILABLE_ICONS: IconTypes[] = STATIC_ICONS;
