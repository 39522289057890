exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-components-component-component-tsx": () => import("./../../../src/components/Component/Component.tsx" /* webpackChunkName: "component---src-components-component-component-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-accessibility-tsx": () => import("./../../../src/pages/accessibility.tsx" /* webpackChunkName: "component---src-pages-accessibility-tsx" */),
  "component---src-pages-accessory-components-tsx": () => import("./../../../src/pages/accessory-components.tsx" /* webpackChunkName: "component---src-pages-accessory-components-tsx" */),
  "component---src-pages-components-tsx": () => import("./../../../src/pages/components.tsx" /* webpackChunkName: "component---src-pages-components-tsx" */),
  "component---src-pages-contributing-tsx": () => import("./../../../src/pages/contributing.tsx" /* webpackChunkName: "component---src-pages-contributing-tsx" */),
  "component---src-pages-deprecation-tsx": () => import("./../../../src/pages/deprecation.tsx" /* webpackChunkName: "component---src-pages-deprecation-tsx" */),
  "component---src-pages-figma-cheatsheet-index-tsx": () => import("./../../../src/pages/figma-cheatsheet/index.tsx" /* webpackChunkName: "component---src-pages-figma-cheatsheet-index-tsx" */),
  "component---src-pages-figma-cheatsheet-tsx": () => import("./../../../src/pages/figma-cheatsheet/[...].tsx" /* webpackChunkName: "component---src-pages-figma-cheatsheet-tsx" */),
  "component---src-pages-form-components-tsx": () => import("./../../../src/pages/form-components.tsx" /* webpackChunkName: "component---src-pages-form-components-tsx" */),
  "component---src-pages-formatter-components-tsx": () => import("./../../../src/pages/formatter-components.tsx" /* webpackChunkName: "component---src-pages-formatter-components-tsx" */),
  "component---src-pages-getting-started-tsx": () => import("./../../../src/pages/getting-started.tsx" /* webpackChunkName: "component---src-pages-getting-started-tsx" */),
  "component---src-pages-guides-tsx": () => import("./../../../src/pages/guides.tsx" /* webpackChunkName: "component---src-pages-guides-tsx" */),
  "component---src-pages-hooks-tsx": () => import("./../../../src/pages/hooks.tsx" /* webpackChunkName: "component---src-pages-hooks-tsx" */),
  "component---src-pages-illustration-components-tsx": () => import("./../../../src/pages/illustration-components.tsx" /* webpackChunkName: "component---src-pages-illustration-components-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-layout-components-tsx": () => import("./../../../src/pages/layout-components.tsx" /* webpackChunkName: "component---src-pages-layout-components-tsx" */),
  "component---src-pages-navigation-components-tsx": () => import("./../../../src/pages/navigation-components.tsx" /* webpackChunkName: "component---src-pages-navigation-components-tsx" */),
  "component---src-pages-patterns-tsx": () => import("./../../../src/pages/patterns.tsx" /* webpackChunkName: "component---src-pages-patterns-tsx" */),
  "component---src-pages-preferences-tsx": () => import("./../../../src/pages/preferences.tsx" /* webpackChunkName: "component---src-pages-preferences-tsx" */),
  "component---src-pages-spreadsheet-components-tsx": () => import("./../../../src/pages/spreadsheet-components.tsx" /* webpackChunkName: "component---src-pages-spreadsheet-components-tsx" */),
  "component---src-pages-table-components-tsx": () => import("./../../../src/pages/table-components.tsx" /* webpackChunkName: "component---src-pages-table-components-tsx" */),
  "component---src-pages-typography-components-tsx": () => import("./../../../src/pages/typography-components.tsx" /* webpackChunkName: "component---src-pages-typography-components-tsx" */),
  "component---src-pages-usage-components-deprecated-index-tsx": () => import("./../../../src/pages/usage/components/deprecated/index.tsx" /* webpackChunkName: "component---src-pages-usage-components-deprecated-index-tsx" */),
  "component---src-pages-usage-components-index-tsx": () => import("./../../../src/pages/usage/components/index.tsx" /* webpackChunkName: "component---src-pages-usage-components-index-tsx" */),
  "component---src-pages-usage-components-tsx": () => import("./../../../src/pages/usage/components/[...].tsx" /* webpackChunkName: "component---src-pages-usage-components-tsx" */),
  "component---src-pages-usage-index-tsx": () => import("./../../../src/pages/usage/index.tsx" /* webpackChunkName: "component---src-pages-usage-index-tsx" */),
  "component---src-pages-usage-pages-index-tsx": () => import("./../../../src/pages/usage/pages/index.tsx" /* webpackChunkName: "component---src-pages-usage-pages-index-tsx" */),
  "component---src-templates-pattern-page-tsx": () => import("./../../../src/templates/PatternPage.tsx" /* webpackChunkName: "component---src-templates-pattern-page-tsx" */)
}

